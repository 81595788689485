import { Link } from "gatsby";
import React from "react";
import { Button } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import BackgroundIcon from "../images/Homepage hero bg logo.svg";

const Hero = ({
	title,
	subHeading = "IT SUPPORT SERVICES",
	url,
	hideButton,
	buttonTarget = "",
	buttonLink = "/contact-us",
	buttonTitle = "Contact Us",
	bannerImage,
}) => {
	return (
		<section className="py-md-7  py-5 py-lg-10 position-relative">
			<div
				style={{ right: "7rem" }}
				className="position-absolute d-none d-lg-block top--10"
			>
				<img
					alt=""
					className=""
					style={{ maxWidth: "500px" }}
					src={bannerImage ?? BackgroundIcon}
				/>
			</div>
			<div
				style={{ right: "2rem", top: "-4rem" }}
				className="position-absolute d-md-none "
			>
				<img
					alt=""
					className=""
					style={{ maxWidth: "200px" }}
					src={bannerImage ?? BackgroundIcon}
				/>
			</div>
			<div
				style={{ right: "4rem", top: "0rem" }}
				className="position-absolute d-none d-md-block d-lg-none "
			>
				<img
					alt=""
					className=""
					style={{ maxWidth: "300px" }}
					src={bannerImage ?? BackgroundIcon}
				/>
			</div>
			<Container style={{ zIndex: 2 }} className="position-relative">
				<Row>
					<Col className="text-xl-center">
						<h2 className="text-light-grey d-none d-lg-block fs-3">
							{subHeading}
						</h2>
						<h2 className="text-light-grey d-lg-none fs-5">{subHeading}</h2>
						<h1 className="text-white display-4">{title}</h1>

						{!hideButton && (
							// <Button
							// 	as={Link}
							// 	to={url}
							// 	variant="primary"
							// 	className="px-5 mt-xl-5 mt-4 mb-3 mb-md-0  py-3 w-100 w-md-auto"
							// >
							// 	Book a discovery call
							// </Button>
							<a
								className="btn btn-primary px-5 mt-xl-5 mt-4 mb-3 mb-md-0 me-md-3 py-3 w-100 w-md-auto"
								href={buttonLink}
								target={buttonTarget}
								rel="noopener noreferrer"
							>
								{buttonTitle}
							</a>
						)}
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default Hero;
